import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { QRCodeSVG } from 'qrcode.react';
import md5 from 'crypto-js/md5'
import { route } from 'preact-router';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';
import ImageScroller from '../../components/imagescroller';
import InspectionStatus from '../../components/inspection/status';
import GuiList from '../../components/gui/list';
import StarRating from '../../components/gui/starRating';

@withText(props => ({
    titleText: <Text id='part.title'>Parts</Text>,
}))
@observer
class PartList extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            filterByTags: [],
        };
    }

    editPartViaView = e => {
        const { id, title } = e.target.closest('button').dataset;
        const { drawerLevel = 1 } = this.props;
        const { appState, installationStore } = this.props.stores;
        const { installation } = installationStore;

        const { drawerHeightMedium } = appState;
		appState.openDrawer('editFile', {
            id,
			height: drawerHeightMedium,
            installationId: installation.id,
            callback: this.closeAddFile,
		}, drawerLevel + 2);
	}

    viewPart = (e, props) => {
        const { view } = this.props;
        if (view) {
            return view(e, props);
        }
        const { id, title } = props ? props : e.target.closest('.box-line').dataset;
        const { appState } = this.props.stores;
        const { drawerLevel = 1 } = this.props;
        const { drawerHeightMedium } = appState;
        // console.log('viewPart', { id });
        appState.openDrawer('viewPart', {
            id,
            // title,
            height: drawerHeightMedium,
            callback: () => {
                // appState.toggleDrawer();
                // this.loadAll();
            },
            editFunction: this.editPartViaView,
        }, drawerLevel + 1);
    }

    sortFunc = e => {
        const { appState, partStore } = this.props.stores;
        const { sortBy, sortTitle } = partStore;
        const { drawerLevel = 1 } = this.props;
        const { drawerHeightSmall } = appState;
        // console.log('sortFunc');
        appState.openDrawer('sortPart', {
            height: drawerHeightSmall,
        }, drawerLevel + 1);
    }

    toggleTagFilter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { filterByTags: filterByTagsProps } = this.props;
        if (filterByTagsProps?.length > 0) {
            return;
        }
        const { tag } = e.target.closest('span').dataset;
        const { filterByTags } = this.state;
        if (filterByTags.includes(tag)) {
            const index = filterByTags.indexOf(tag);
            filterByTags.splice(index, 1);
        } else {
            filterByTags.push(tag);
        }
        this.setState({ filterByTags });
    }

    viewTags = obj => {
        if (obj.tags?.length === 0 && obj.loans?.length === 0 && !obj.ownedByOther && !obj.url && !obj.parents?.length > 0 && !obj.files?.length > 0) {
            return '';
        }
        const { userStore } = this.props.stores;
        const { user = {} } = userStore;
        const darkmode = util.getNestedValue(user, 'settings.darkmode');
        const tagClass = darkmode ? 'dark' : 'light';

        return (<>
            <div class='w-100 overflow-hidden'>
                <div class='box-line-tags d-flex flex-nowrap'>
                    {obj.ownedByOther && <>
                        <span class={`badge badge-pill badge-danger mr-1 mt-1`}>
                            <span class='font-weight-lighter'>Owner:</span> {obj.ownerFirstname} {obj.ownerLastname}
                        </span>
                    </>}
                    {obj.parents?.length > 0 && <>
                        <span class={`badge badge-pill badge-light mr-1 mt-1`}>
                            <i class='fa-duotone fa-code-branch' />
                        </span>
                    </>}
                    {obj.url && <>
                        <span class={`badge badge-pill badge-light mr-1 mt-1`}>
                            <i class='fa-duotone fa-link' />
                        </span>
                    </>}
                    {obj.files?.length > 0 && <>
                        <span class={`badge badge-pill badge-light mr-1 mt-1`}>
                            <i class='fa-duotone fa-file' /> <span class='font-weight-lighter'> x {obj.files.length}</span>
                        </span>
                    </>}
                    {obj.tags?.length > 0 && obj.tags.map(tag => {
                        return (<>
                            <span
                                class={`badge badge-pill badge-${tagClass} mr-1 mt-1`}
                                onClick={this.toggleTagFilter}
                                data-tag={tag.name}
                            >#{tag.name}</span>
                        </>);
                    })}
                    {obj.loans?.length > 0 && obj.loans.map(loan => {
                        if (loan.endDate) {
                            return '';
                        }
                        const endDate = loan.endDate ? new Date(loan.endDate) : new Date();
                        const {
                            seconds,
                            minutes,
                            hours,
                            days,
                            weeks,
                            months,
                            years,
                        } = util.dateDiff(loan.startDate, endDate);
                        // console.log('viewTags', obj.startDate, { obj, seconds, minutes, hours, days, weeks, months, years })

                        return (<>
                            <span class={`badge badge-pill badge-${loan.endDate ? 'success' : 'warning'} mr-1 mt-1`}>
                                Loaned out <span class='font-weight-lighter'>({days > 0 ? <>{days}d</> : <>{hours}h</>})</span>
                            </span>

                            {/* <div class='tag'>{JSON.stringify(loan)}</div> */}
                        </>);
                    })}
                </div>
            </div>
        </>);
    }

    filterByTags = (part) => {
        const { filterByTags, hideChildren } = this.state;
        const { filterByTags: filterByTagsProps } = this.props;
        if (hideChildren && part.parent) {
            return false;
        }
        if (filterByTagsProps?.length > 0) {
            return part.tags?.some(tag => filterByTagsProps.includes(tag.name));
        }
        if (filterByTags.length === 0) {
            return true;
        }
        // console.log('filterByTags', { part, filterByTags })
        return part.tags?.some(tag => filterByTags.includes(tag.name));
    }

    filter = () => {
        const { filterByTags } = this.state;
        if (filterByTags.length > 0) {
            return (<>
                {filterByTags.map(tag => {
                    return (<>
                        <span
                            class='badge badge-pill badge-secondary mr-1'
                            style='font-size: 1.0rem;'
                            onClick={this.toggleTagFilter}
                            data-tag={tag}
                        ><i class="fa-solid fa-circle-xmark"></i> {tag}</span>
                    </>);
                })}
            </>);
        }
    }

    toggleChildren = e => {
        e.preventDefault();
        e.stopPropagation();
        const { hideChildren } = this.state;
        this.setState({ hideChildren: !hideChildren });
    }

    showIncDec = obj => {
        return obj.isConsumable;
    }

    render() {
        const { appState, userStore, partStore } = this.props.stores;
        const { isDevelopment, path, apiServer, opts = {} } = appState;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { hideChildren } = this.state;

        const {
            sortBy,
            sortTitle,
        } = partStore;

        const { titleText } = this.props;
        const {
            title = titleText,
            parts = [],
            classNames = '',
            hideEmpty,
            addItem,
            inc,
            dec,
            remove,
            editQty,
            viewTags = this.viewTags,
            deleteElement,
            rentOutElement,
            moveElement,
            addUsageData,
            editElement,
            refreshElement,
            filterByTags,
            help,
            children,

            rightBoxField = 'children',
            rightBoxFieldValue = e => e && e.length > 0 ? e.length : null,
            rightBoxFieldPostfix = 'parts',
            // rightBoxFieldPostfixField = 'children',
            // rightBoxFieldPostfixValue = 'parts',
        } = this.props;

        return (<>
            <Localizer>
                <GuiList
                    stores={this.props.stores}
                    holdingStore={partStore}
                    classNames={classNames}
                    view={this.viewPart}
                    title={title}
                    filter={this.filter}
                    list={parts.filter(this.filterByTags)}
                    sortBy={sortBy}
                    sortTitle={sortTitle}
                    sortFunc={opts.showSortParts ? this.sortFunc : null}
                    toggle={hideChildren}
                    toggleTitle={<Text id='part.filter.hide-children'>hide children</Text>}
                    toggleFunc={this.toggleChildren}
                    elTitle={e => e.name}
                    elInfo1={e => e.rating ? <>
                        <StarRating rating={e.rating} />
                    </> : ''}
                    elInfo2={e => ''}
                    elInfo3={e => ''}
                    viewTags={viewTags}
                    hideEmpty={hideEmpty}
                    addItem={addItem}
                    showIncDec={this.showIncDec}
                    inc={inc}
                    dec={dec}
                    remove={remove}
                    editQty={editQty}
                    deleteElement={deleteElement}
                    editElement={editElement}
                    refreshElement={refreshElement}
                    rentOutElement={rentOutElement}
                    moveElement={moveElement}
                    addUsageData={addUsageData}
                    imgHeight='70px'
                    help={help || children}
                    rightBoxField={rightBoxField}
                    rightBoxFieldValue={rightBoxFieldValue}
                    rightBoxFieldPostfix={rightBoxFieldPostfix}
                    // rightBoxFieldPostfixField={rightBoxFieldPostfixField}
                    // rightBoxFieldPostfixValue={rightBoxFieldPostfixValue}
                />
            </Localizer>
        </>);
    }
}

export default PartList;
