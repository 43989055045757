import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';
import linkstate from 'linkstate';
import md5 from 'crypto-js/md5';

import fields from '../../lib/fields';
import ImageScroller from '../../components/imagescroller';
import InstallationList from '../../components/installation/list';
import PartLoanList from '../../components/part/loanList';
import PartUsageList from '../../components/part/usageList';
import PartList from '../../components/part/list';
import MiniGraph from '../../components/lineGraph/mini';
import FileList from '../../components/fileViewer/list';
import UrlList from '../../components/urlViewer/list';
import GradientRight from '../../components/gui/gradientRight';
import HelpText from '../../components/gui/helpText';
import StarRating from '../../components/gui/starRating';
import Input from '../../components/form/input';

const MARKDOWN_OPTIONS = {
	pedantic: false,
	gfm: true,
	breaks: true,
	sanitize: false,
	smartLists: true,
	smartypants: true,
	xhtml: true,
    highlight: function(code, lang) {
        const hljs = require('highlight.js');
        const language = hljs.getLanguage(lang) ? lang : 'plaintext';
        return hljs.highlight(code, { language }).value;
      },
    langPrefix: 'hljs language-', // highlight.js css expects a top-level 'hljs' class.
};

function partImg(img, props, size = '150x') {
    if (typeof img !== 'object') {
        return undefined;
    }
    const imgSrc = `${img.s3MediumLink}`;
    return imgSrc;
}


@withText(props => ({
    installationListHelpText: <Text id='part.installation-list-help'>List of all the places where this part is currently located.</Text>,
    partUsageListHelpText: <Text id='part.usage-list-help'>All logged usage of this part. This can be service of a car, boat trips, shooting practice, etc.</Text>,
    partLoanListHelpText: <Text id='part.loan-list-help'>All loan outs of this part. You can see if it's still out or if it has been returned. You can also see if you have sent any reminders.</Text>,
    titleChildrenPartsText: <Text id='part.children-list-title'>Connected parts</Text>,
    titleParentPartsText: <Text id='part.part-list-title'>Parent part</Text>,
}))
@observer
class Part extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sessionid: new Date().getTime(),
        };
        this.mainContainer = null;
    }

    loadAll = async (props = this.props, emptyFirst = true) => {
        const { id, skipUpdate, callback = () => {} } = props;
        const { partStore, installationStore } = this.props.stores;
        const { installation } = installationStore;

        if (emptyFirst && !skipUpdate) {
            partStore.updateKeyValue('part', {});
        }
        // await partStore.load(id, false, { query:{ installationId: installation.id }, addData: ['installations'] });
        const localPart = await partStore.load(id, false, { query:{}, addData: ['installations', 'existingTags'], skipUpdate });
        this.setState({ localPart });
        callback();
    }

    editPart = () => {
		const { id, drawerLevel = 1, skipUpdate } = this.props;
		const { appState, installationStore } = this.props.stores;
        const { installation } = installationStore;

		const { drawerHeightMedium } = appState;
		appState.openDrawer('editPart', {
			height: drawerHeightMedium,
			id,
            installationId: installation.id,
            skipUpdate,
		}, drawerLevel + 1);
	}

    editPartLoan = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { id: loanId, qty = 1 } = e.target.closest('.box-line').dataset;
        const { id, drawerLevel = 1 } = this.props;
        const { appState, installationStore } = this.props.stores;
		const { drawerHeightMedium } = appState;
        const { installation } = installationStore;

		appState.openDrawer('editLoan', {
			height: drawerHeightMedium,
            id,
            loanId,
            callback: this.closeAddPart,
		}, drawerLevel + 1);
    }

    editPartUsage = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { id: usageId, qty = 1 } = e.target.closest('.box-line').dataset;
        const { id, drawerLevel = 1 } = this.props;
        const { appState, installationStore } = this.props.stores;
		const { drawerHeightMedium } = appState;
        const { installation } = installationStore;

		appState.openDrawer('editUsage', {
			height: drawerHeightMedium,
            id,
            usageId,
            callback: () => {
                this.closeAddPart;
                setTimeout(() => {
                    this.loadAll(this.props, false);
                }, 1000);
            },
		}, drawerLevel + 1);
    }

    viewPart = e => {
        const { id, title } = e.target.closest('.box-line').dataset;
        const { appState, installationStore } = this.props.stores;
        const { installation } = installationStore;
        // appState.toggleDrawer();

        const { drawerLevel = 1 } = this.props;
        const { drawerHeightMediumLarge } = appState;
        appState.openDrawer('viewPart', {
            id,
            // title,
            height: drawerHeightMediumLarge,
            callback: () => {
                // appState.toggleDrawer();
                setTimeout(() => {
                    this.loadAll(this.props, false);
                }, 1000);
            },
            skipUpdate: true,
            installation,
        }, drawerLevel + 1);
    }

    viewPartLoan = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { id: loanId, qty = 1 } = e.target.closest('.box-line').dataset;
        const { id, drawerLevel = 1 } = this.props;
        const { appState, installationStore } = this.props.stores;
		const { drawerHeightMediumLarge } = appState;
        const { installation } = installationStore;

		appState.openDrawer('viewLoan', {
			height: drawerHeightMediumLarge,
            id,
            loanId,
		}, drawerLevel + 1);
    }

    viewPartUsage = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { id: usageId, qty = 1 } = e.target.closest('.box-line').dataset;
        const { id, drawerLevel = 1 } = this.props;
        const { appState, installationStore } = this.props.stores;
		const { drawerHeightMediumLarge } = appState;
        const { installation } = installationStore;

		appState.openDrawer('viewUsage', {
			height: drawerHeightMediumLarge,
            id,
            usageId,
            callback: () => {
                setTimeout(() => {
                    this.loadAll(this.props, false);
                }, 1000);
            },
		}, drawerLevel + 1);
    }

    closeAddPart = () => {
        const { drawerLevel = 1 } = this.props;
        const { appState } = this.props.stores;
        appState.closeDrawer(false, drawerLevel + 2);
        // this.loadAll(this.props, false);
    }

    addToInstallation = e => {
        const { id: partId, drawerLevel = 1 } = this.props;
		const { appState } = this.props.stores;
		const { drawerHeightMedium } = appState;
		appState.openDrawer('addPartToInstallation', {
			height: drawerHeightMedium,
			partId,
            callback: () => {
                setTimeout(() => {
                    this.loadAll(this.props, false);
                }, 1000);
            },
		}, drawerLevel + 1);
    }

    loanToFriend = e => {
        const { id: partId, drawerLevel = 1, installation = {} } = this.props;
		const { appState } = this.props.stores;
		const { drawerHeightMedium } = appState;

		appState.openDrawer('loanPartToFriend', {
			height: drawerHeightMedium,
			partId,
            installationId: installation.id,
            callback: () => {
                setTimeout(() => {
                    this.loadAll(this.props, false);
                }, 1000);
            },
		}, drawerLevel + 1);
    }

    addUsageData = e => {
        const { id, drawerLevel = 1 } = this.props;
		const { appState } = this.props.stores;
		const { drawerHeightMedium } = appState;

		appState.openDrawer('partAddUsageData', {
			height: drawerHeightMedium,
			id,
            callback: () => {
                setTimeout(() => {
                    this.loadAll(this.props, false);
                }, 1000);
            },
		}, drawerLevel + 1);
    }

    deletePartFromInstallation = async (e) => {
        const { id: partId, drawerLevel = 1, installation = {} } = this.props;
        const { appState } = this.props.stores;
        const { drawerHeightSmall } = appState;
        appState.openDrawer('deletePartFromInstallation', {
            height: drawerHeightSmall,
            installationId: installation.id,
            partId,
            callback: () => {
                setTimeout(() => {
                    this.loadAll(this.props, false);
                }, 1000);
            },
        }, drawerLevel + 1);
    }

    addTag = e => {
        const { tagInput = '' } = this.state;
        const { partStore } = this.props.stores;
        const value = {
            name: tagInput.toLowerCase().trim(),
            date: new Date(),
        };
        value.md5 = md5(JSON.stringify(value)).toString();

        const field = 'tags';
        const object = partStore.part;
        const tagExists = object[field] && object[field].find(tag => tag.name === value.name);
        if (!tagExists) {
            const localValue = object[field] ? [...object[field]] : [];
            partStore.saveField(object.id, field, value);
            partStore.updateField(object.id, field, [...localValue, value]);
        }
        this.setState({ tagInput: '' });
    }

    checkForEnter = (e) => {
        if (e.key === 'Enter') {
            this.addTag();
        }
    }

    editPartQty = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { drawerLevel = 1 } = this.props;
        const { id: partId, qty = 1 } = e.target.closest('div').dataset;
        const { id } = this.props;
        const { appState, installationStore } = this.props.stores;
		const { drawerHeightMedium } = appState;
        const { installation } = installationStore;

		appState.openDrawer('editInstallationPart', {
			height: drawerHeightMedium,
            id: installation.id,
            partId,
            callback: this.closeDrawerCallback,
		}, drawerLevel + 1);
    }

    sendOwnerReminder = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { id } = this.props;
        const { partStore } = this.props.stores;
        await partStore.sendOwnerReminder(id);
        this.setState({ ownerReminderSent: true });
        this.loadAll(this.props, false);
    }

    sendOwnerExcuse = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { id } = this.props;
        const { partStore } = this.props.stores;
        await partStore.sendOwnerExcuse(id);
        this.setState({ ownerExcuseSent: true });
        this.loadAll(this.props, false);
    }

    updatePartUrl = async (e, idx = 1) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ [`updatingPartUrl${idx}`]: true });
        const { id } = this.props;
        const { partStore } = this.props.stores;
        await partStore.updatePartUrl(id, idx);
        await this.loadAll(this.props, false);
        this.setState({ [`updatingPartUrl${idx}`]: false });
    }

    updatePartUrl2 = async (e) => {
        this.updatePartUrl(e, 2);
    }

    updatePartUrl3 = async (e) => {
        this.updatePartUrl(e, 3);
    }

    updatePartUrl4 = async (e) => {
        this.updatePartUrl(e, 4);
    }

    updatePartUrl5 = async (e) => {
        this.updatePartUrl(e, 5);
    }

    componentDidMount() {
        this.loadAll();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.page !== this.props.page || nextProps.artid !== this.props.artid) {
            this.loadAll(nextProps);
        }
    }

    componentWillUnmount() {

    }

    render() {
        const {
            installationListHelpText,
            partUsageListHelpText,
            partLoanListHelpText,
            titleChildrenPartsText,
            titleParentPartsText,
            drawerLevel = 1,
        } = this.props;
        const {
            page,
            artid,
            bib,
            showAnimations = true,
            id,
            installation,
        } = this.props;
        const {
            sessionid,
            showMenu,
            tagInput,
            ownerReminderSent,
            ownerExcuseSent,
            localPart,
            updatingPartUrl1,
            updatingPartUrl2,
            updatingPartUrl3,
            updatingPartUrl4,
            updatingPartUrl5,
        } = this.state;
        const { appState, userStore, installationStore, inspectionStore, partStore } = this.props.stores;
        const { mainView, subView, isDevelopment, path, opts = {} } = appState;
        const { user = {}, isAdmin, isTester, isVeterinary } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { inspections } = inspectionStore;
        const { part: currentPart, installations } = partStore;
        const part = Array.isArray(localPart) && localPart[0] ? localPart[0] : currentPart;

        const endDate = part.loaner?.endDate ? new Date(part.loaner?.endDate) : new Date();
        const {
            seconds,
            minutes,
            hours,
            days,
            weeks,
            months,
            years,
        } = util.dateDiff(part.loaner?.startDate, endDate);
        // console.log('viewTags', obj.startDate, { obj, seconds, minutes, hours, days, weeks, months, years })

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>

                    {/* {isAdmin && <div class={`float-right mr-5`}>
                        <button onClick={this.editPart} class={`btn btn-sm btn-outline-secondary rounded-pill mr-2`}>
                            <i class={`fa-regular fa-pen mr-2`} />
                            <Text id='part.edit'>Edit part</Text>
                        </button>
                    </div>}

                    <div class='font-weight-lighter px-3 box-header'>
                        <Text id='part.view'>View part</Text>
                    </div> */}
                    <div class='w-100 d-flex flex-column'>
                        <div class='px-0 box-header-info d-flex flex-column'>
                            <div class='d-flex px-3'>
                                <ImageScroller images={part.images} stores={this.props.stores} showImg={img => partImg(img, this.props, '1920x')} />
                            </div>
                            <div class={`d-flex flex-row mt-3 mx-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                <div
                                    class='box-line-qty text-right'
                                    onClick={this.editPartQty}
                                    data-id={part.id}
                                    data-qty={part.qty}
                                >
                                    {part.qty}
                                </div>
                                <div class={`flex-fill d-flex flex-column px-3 py-3 text-overflow position-relative`}>
                                    <GradientRight stores={this.props.stores} styles={'border-radius: 0 1.0rem 1.0rem 0 !important;'} />

                                    {part.name && <div class='d-flex flex-row'>
                                        <span class='font-weight-lighter text-muted'>
                                            <Text id='part.name'>Name</Text>
                                        </span>
                                        <span class='ml-2'>{part.name}</span>
                                    </div>}

                                    {['sold', 'brand', 'model', 'partNumber', 'serialNumber', 'regNumber', 'description', 'purchaseDate', 'serviceDate', 'totalDistance',
                                        'totalHours', 'currency', 'price', 'latestPrice', 'sku', 'mpn', 'gtin13', 'commodityCode', 'category'].map(key => {
                                        if (!part[key]) {
                                            return '';
                                        }
                                        const field = fields.getField('part', key);
                                        const displayValue = field.displayValue || (value => value);
                                        return (<>
                                            <div class='d-flex flex-row'>
                                                <span class='font-weight-lighter text-muted'>
                                                    {field.title}
                                                </span>
                                                {field.type === 'textarea' ? <>
                                                    <div class='ml-2  mb-4 text-wrap' style='max-height: 20vh; overflow: auto;'>
                                                        <Markdown markdown={displayValue(part[key])} markedOpts={MARKDOWN_OPTIONS} />
                                                    </div>
                                                </> : <>
                                                    <span class='ml-2'>
                                                        {displayValue(part[key])}
                                                    </span>
                                                </>}
                                            </div>
                                        </>);
                                    })}

                                    {part.url && <div class='d-flex flex-row'>
                                        <span class='font-weight-lighter text-muted'>
                                            <Text id={`part.url`}>URL</Text>
                                        </span>
                                        <span class='ml-2'>
                                            <button type='button' class={`btn btn-sm btn-${util.isDefined(updatingPartUrl1) ? 'success' : 'secondary'} rounded-pill`} onClick={this.updatePartUrl}>
                                                <i class={`fa-solid ${updatingPartUrl1 ? 'fa-arrows-rotate fa-spin' : 'fa-refresh'}`} />
                                            </button>
                                        </span>
                                        <span class='ml-2'>
                                            <UrlList stores={this.props.stores} urls={[part.url]} drawerLevel={drawerLevel} />
                                        </span>
                                    </div>}

                                    {part.name2 && <div class='d-flex flex-row mt-3'>
                                        <span class='font-weight-lighter text-muted'>
                                            <Text id='part.name'>Name</Text> 2
                                        </span>
                                        <span class='ml-2'>{part.name2}</span>
                                    </div>}
                                    {part.url2 && <div class='d-flex flex-row'>
                                        <span class='font-weight-lighter text-muted'>
                                            <Text id={`part.url2`}>URL 2</Text>
                                        </span>
                                        <span class='ml-2'>
                                        <button type='button' class={`btn btn-sm btn-${util.isDefined(updatingPartUrl2) ? 'success' : 'secondary'} rounded-pill`} onClick={this.updatePartUrl2}>
                                                <i class={`fa-solid ${updatingPartUrl2 ? 'fa-arrows-rotate fa-spin' : 'fa-refresh'}`} />
                                            </button>
                                        </span>
                                        <span class='ml-2'>
                                            <UrlList stores={this.props.stores} urls={[part.url2]} />
                                        </span>
                                    </div>}
                                    {part.description2 && <div class='d-flex flex-row'>
                                        <span class='font-weight-lighter text-muted'>
                                            <Text id='part.description'>Description</Text> 2
                                        </span>
                                        <span class='ml-2'>
                                            <Markdown markdown={part.description2} markedOpts={MARKDOWN_OPTIONS} />
                                        </span>
                                    </div>}


                                    {part.name3 && <div class='d-flex flex-row mt-3'>
                                        <span class='font-weight-lighter text-muted'>
                                            <Text id='part.name'>Name</Text> 3
                                        </span>
                                        <span class='ml-2'>{part.name3}</span>
                                    </div>}
                                    {part.url3 && <div class='d-flex flex-row'>
                                        <span class='font-weight-lighter text-muted'>
                                            <Text id={`part.url3`}>URL 3</Text>
                                        </span>
                                        <span class='ml-2'>
                                        <button type='button' class={`btn btn-sm btn-${util.isDefined(updatingPartUrl3) ? 'success' : 'secondary'} rounded-pill`} onClick={this.updatePartUrl3}>
                                                <i class={`fa-solid ${updatingPartUrl3 ? 'fa-arrows-rotate fa-spin' : 'fa-refresh'}`} />
                                            </button>
                                        </span>
                                        <span class='ml-2'>
                                            <UrlList stores={this.props.stores} urls={[part.url3]} />
                                        </span>
                                    </div>}
                                    {part.description3 && <div class='d-flex flex-row'>
                                        <span class='font-weight-lighter text-muted'>
                                            <Text id='part.description'>Description</Text> 3
                                        </span>
                                        <span class='ml-2'>
                                            <Markdown markdown={part.description3} markedOpts={MARKDOWN_OPTIONS} />
                                        </span>
                                    </div>}

                                    {part.name4 && <div class='d-flex flex-row mt-3'>
                                        <span class='font-weight-lighter text-muted'>
                                            <Text id='part.name'>Name</Text> 4
                                        </span>
                                        <span class='ml-2'>{part.name4}</span>
                                    </div>}
                                    {part.url4 && <div class='d-flex flex-row'>
                                        <span class='font-weight-lighter text-muted'>
                                            <Text id={`part.url4`}>URL 4</Text>
                                        </span>
                                        <span class='ml-2'>
                                        <button type='button' class={`btn btn-sm btn-${util.isDefined(updatingPartUrl4) ? 'success' : 'secondary'} rounded-pill`} onClick={this.updatePartUrl4}>
                                                <i class={`fa-solid ${updatingPartUrl4 ? 'fa-arrows-rotate fa-spin' : 'fa-refresh'}`} />
                                            </button>
                                        </span>
                                        <span class='ml-2'>
                                            <UrlList stores={this.props.stores} urls={[part.url4]} />
                                        </span>
                                    </div>}
                                    {part.description4 && <div class='d-flex flex-row'>
                                        <span class='font-weight-lighter text-muted'>
                                            <Text id='part.description'>Description</Text> 4
                                        </span>
                                        <span class='ml-2'>
                                            <Markdown markdown={part.description4} markedOpts={MARKDOWN_OPTIONS} />
                                        </span>
                                    </div>}

                                    {part.name5 && <div class='d-flex flex-row mt-3'>
                                        <span class='font-weight-lighter text-muted'>
                                            <Text id='part.name'>Name</Text> 5
                                        </span>
                                        <span class='ml-2'>{part.name5}</span>
                                    </div>}
                                    {part.url5 && <div class='d-flex flex-row'>
                                        <span class='font-weight-lighter text-muted'>
                                            <Text id={`part.url5`}>URL 5</Text>
                                        </span>
                                        <span class='ml-2'>
                                        <button type='button' class={`btn btn-sm btn-${util.isDefined(updatingPartUrl5) ? 'success' : 'secondary'} rounded-pill`} onClick={this.updatePartUrl5}>
                                                <i class={`fa-solid ${updatingPartUrl5 ? 'fa-arrows-rotate fa-spin' : 'fa-refresh'}`} />
                                            </button>
                                        </span>
                                        <span class='ml-2'>
                                            <UrlList stores={this.props.stores} urls={[part.url5]} />
                                        </span>
                                    </div>}
                                    {part.description5 && <div class='d-flex flex-row'>
                                        <span class='font-weight-lighter text-muted'>
                                            <Text id='part.description'>Description</Text> 5
                                        </span>
                                        <span class='ml-2'>
                                            <Markdown markdown={part.description5} markedOpts={MARKDOWN_OPTIONS} />
                                        </span>
                                    </div>}

                                    {(part.rating && part.rating) > 0 && <div class='d-flex flex-row mt-3'>
                                        <StarRating rating={part.rating} />
                                        <span class='ml-2'>
                                            {part.rating}
                                        </span>
                                        <span class='ml-2'>
                                            ({part.ratingCount})
                                        </span>
                                    </div>}

                                    <div class='d-flex flex-row justify-content-center'>
                                        <a href={`https://www.google.com/search?q=${part.name}`} target='_blank' rel='noopener noreferrer' class='btn btn-primary rounded-pill'>
                                            <i class='fa-brands fa-google' />
                                        </a>
                                        <a href={`https://www.youtube.com/results?search_query=${part.name}`} target='_blank' rel='noopener noreferrer' class='ml-2 btn btn-danger rounded-pill'>
                                            <i class='fa-brands fa-youtube' />
                                        </a>
                                    </div>

                                </div>
                            </div>

                            {part.product?.review?.length > 0 && <>
                                <div class='font-weight-lighter px-3 mt-4 box-header'>
                                    <Text id='part.reviews'>Part reviews</Text>
                                </div>
                                <div class={`d-flex flex-column mt-1 mx-3 px-3 py-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                    <div class='d-flex flex-column w-100'  style='max-height: 25vh; overflow: auto;'>
                                    {part.product?.review?.map(review => {
                                        return (<>
                                            <div class='d-flex flex-row mb-3'>
                                                <span class='font-weight-lighter text-muted w-20'>
                                                    <div dangerouslySetInnerHTML={{ __html: review.author.name }} />
                                                    <div class=''>
                                                        <small>
                                                            {util.isoDate(review.datePublished)}
                                                        </small>
                                                    </div>
                                                </span>
                                                <span class='ml-2 w-80'>
                                                    <strong dangerouslySetInnerHTML={{ __html: review.name }} />
                                                    <div dangerouslySetInnerHTML={{ __html: review.reviewBody }} />
                                                    <div class='d-flex flex-row mt-1'>
                                                        <span>
                                                            <Text id='part.rating'>Rating</Text>: <StarRating rating={review.reviewRating.ratingValue} />
                                                        </span>
                                                    </div>

                                                    {/* <xmp>{JSON.stringify(review, null, 4)}</xmp> */}
                                                </span>
                                            </div>
                                        </>);
                                    })}
                                    </div>
                                </div>
                            </>}

                            <div class={`d-flex flex-column mt-3 mx-3 px-3 py-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                {part.files?.length > 0 && <div class='d-flex flex-column'>
                                    <span class='font-weight-lighter text-muted'>
                                        <Text id='part.documentation'>Documentation</Text>
                                    </span>
                                    <div class={`d-flex flex-column`}>
                                        <FileList files={part.files} stores={this.props.stores} />
                                    </div>
                                </div>}

                                <Input
                                    stores={this.props.stores}
                                    holdingStore={partStore}
                                    field='tags'
                                    object={part}
                                    objectName='part'
                                    type='tags'
                                    lookupArray={'existingTags'}
                                />

                            </div>
                            <HelpText>
                                <Text id='part.tag-help'>Add tags to this part to make it easier to find and organize. Tip: Use general tags like: photo, fishing, car, boat...</Text>
                            </HelpText>

                                {/*
                                    TODO: New features:
                                    - Show history for part
                                    - Show loan status for part
                                    - Track usage of part: Hours, distance, position, etc.
                                */}

                            {part.isConsumable && installation?.partsHistory?.length > 0 && <>
                                <div class={`mt-3 d-flex flex-column mx-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3`}>
                                    <span class='font-weight-lighter text-muted'>
                                        <Text id='part.history'>History</Text>
                                    </span>
                                    {/* <div>
                                        <small>
                                            {installation.partsHistory.filter(p => p.part === part.id).map(history => {
                                                return (<>
                                                    <div class='d-flex flex-row'>
                                                        {util.isoDate(history.date)}: {history.qty}
                                                    </div>
                                                </>);
                                            })}
                                        </small>
                                    </div> */}
                                    <div>
                                        <MiniGraph stores={this.props.stores}
                                            fieldsLeft={[]}
                                            legendLeft={[]}
                                            dataLeft={[...installation.partsHistory.filter(p => p.part === part.id)]}
                                            fieldsRight={[]}
                                            legendRight={[]}
                                            key={`part-history-${part.id}`}
                                        />
                                    </div>
                                </div>
                            </>}

                            {part.status === 'loanedOut' && <>
                                <div class={`mt-3 d-flex flex-column mx-3 rounded-lg px-4 py-3`}>
                                    <span class={`badge badge-pill badge-warning mr-1 mt-2`}>
                                        <Text id='part.loaned-out-to'>Loaned out to</Text> {part.loaner?.firstname} {part.loaner?.lastname} for <span class='font-weight-lighter'>({days > 0 ? <>{days}d</> : <>{hours}h</>})</span>
                                    </span>
                                </div>
                            </>}

                        </div>
                    </div>


                    <div class='d-flex flex-row justify-content-center mt-5 px-3 swipe-container text-center'>
                        {opts.showLoan && !installation.isWishList && <>
                            <div
                                class={`bg-success text-white d-flex flex-column justify-content-center align-items-center flex-fill list-button py-3`}
                                onClick={this.loanToFriend}
                                data-id={part.id} disabled={!part.isConsumable && part.status === 'loanedOut'}
                            >
                                <i class='fa-light fa-truck-ramp-box' />
                                <small class='mt-2' style='line-height: 1.0em;'>
                                    <Text id='part.loan-to-friend'>Loan to a friend</Text>
                                </small>
                            </div>
                        </>}
                        {opts.showAddLog && !installation.isWishList && <>
                            <div
                                class='bg-warning text-white d-flex flex-column justify-content-center align-items-center flex-fill list-button py-3'
                                onClick={this.addUsageData}
                                data-id={part.id}
                            >
                                <i class='fa-light fa-gauge' />
                                <small class='mt-2' style='line-height: 1.0em;'>
                                    <Text id='part.add-usage-data'>Add usage data</Text>
                                </small>
                            </div>
                        </>}
                        {opts.showAddToInstallation && <>
                            <div
                                class='bg-primary text-white d-flex flex-column justify-content-center align-items-center flex-fill list-button py-3'
                                onClick={this.addToInstallation}
                                data-id={part.id}
                            >
                                <i class='fa-light fa-house-circle-check' />
                                <small class='mt-2' style='line-height: 1.0em;'>
                                    <Text id='part.add-to-installation'>Add to installation</Text>
                                </small>
                            </div>
                        </>}
                        {opts.showDeleteFromInstallation && <>
                            <div
                                class='bg-danger text-white d-flex flex-column justify-content-center align-items-center flex-fill list-button'
                                onClick={this.deletePartFromInstallation}
                                data-id={part.id}
                            >
                                <i class='fa-light fa-trash' />
                                <small class='mt-2' style='line-height: 1.0em;'>
                                    <Text id='part.delete-from-installation'>Delete from installation</Text>
                                </small>
                            </div>
                        </>}
                    </div>

                    {part.ownedByOther && <>
                        <div class='font-weight-lighter px-3 box-header mt-5'>
                            <Text id='part.owner'>Owned by</Text>
                        </div>
                        <div class='w-100 d-flex flex-column'>
                            <div class='px-0 box-header-info d-flex flex-column'>
                                <div class={`d-flex flex-column mx-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3`}>
                                    {part.ownerFirstname && <div class='d-flex flex-row'>
                                        <span class='font-weight-lighter text-muted'>
                                            <Text id='part.name'>Name</Text>
                                        </span>
                                        <span class='ml-2'>{part.ownerFirstname} {part.ownerLastname}</span>
                                    </div>}
                                    {part.ownerCellphone && <div class='d-flex flex-row'>
                                        <span class='font-weight-lighter text-muted'>
                                            <Text id='part.cellphone'>Cellphone</Text>
                                        </span>
                                        <span class='ml-2'>{part.ownerCellphone}</span>
                                    </div>}
                                    {part.ownerEmail && <div class='d-flex flex-row'>
                                        <span class='font-weight-lighter text-muted'>
                                            <Text id='part.email'>Email</Text>
                                        </span>
                                        <span class='ml-2'>{part.ownerEmail}</span>
                                    </div>}
                                </div>
                            </div>
                        </div>

                        <div class='font-weight-lighter px-3 box-header mt-5'>
                            <Text id='loan.reminders'>Reminders</Text>
                        </div>
                        <div class={`d-flex flex-column mt-0 mx-3 px-3 py-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} text-overflow position-relative`}>
                            {part.reminders?.length > 0 ? <>
                                <div class='mx-0 d-flex flex-column'>
                                    {part.reminders && part.reminders.map((reminder, idx) => {
                                        return (<>
                                            <div class={`d-flex flex-row text-${reminder.title === 'ownerReminder' ? 'warning' : 'danger'} `}>
                                                <div class='text-center' style='width: 40px;'>
                                                    {reminder.title === 'ownerReminder' ? <><i class='fa-solid fa-hand-point-ribbon mr-1' /></> : <><i class='fa-solid fa-hand-holding-heart mr-1' /></>}
                                                </div>
                                                <div class='flex-fill'>
                                                    {reminder.type}: {util.formatDate(reminder.date, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}
                                                </div>
                                            </div>
                                        </>);
                                    })}
                                </div>
                            </> : <>
                                <div class='d-flex flex-row justify-content-center align-items-center mt-3 px-3 text-muted'>
                                    <i class='fa-solid fa-alarm-clock' /> <span class='ml-2'><Text id='part.owner-reminders'>No reminders sent</Text></span>
                                </div>
                            </>}
                        </div>

                        <div class='d-flex flex-row justify-content-center mt-2 px-3 swipe-container text-center'>
                            {ownerReminderSent ? <>
                                <div
                                    class='bg-light text-success d-flex flex-column justify-content-center align-items-center flex-fill list-button py-3'
                                >
                                    <i class='fa-light fa-hand-point-ribbon' />
                                    <small class='mt-2' style='line-height: 1.0em;'>
                                        <Text id='part.owner-reminder-sent'>Owner reminder sent</Text>
                                    </small>
                                </div>
                            </> : <>
                                <div
                                    class='bg-warning text-white d-flex flex-column justify-content-center align-items-center flex-fill list-button py-3'
                                    onClick={this.sendOwnerReminder}
                                    data-id={part.id}
                                >
                                    <i class='fa-light fa-hand-point-ribbon' />
                                    <small class='mt-2' style='line-height: 1.0em;'>
                                        <Text id='part.send-owner-reminder'>Send owner reminder</Text>
                                    </small>
                                </div>
                            </>}

                            {ownerExcuseSent ? <>
                                <div
                                    class='bg-light text-success d-flex flex-column justify-content-center align-items-center flex-fill list-button py-3'
                                >
                                    <i class='fa-light fa-hand-holding-heart' />
                                    <small class='mt-2' style='line-height: 1.0em;'>
                                        <Text id='part.owner-excuse-sent'>Owner excuse sent</Text>
                                    </small>
                                </div>
                            </> : <>
                                <div
                                    class='bg-danger text-white d-flex flex-column justify-content-center align-items-center flex-fill list-button'
                                    onClick={this.sendOwnerExcuse}
                                    data-id={part.id}
                                >
                                        <i class='fa-light fa-hand-holding-heart' />
                                        <small class='mt-2' style='line-height: 1.0em;'>
                                            <Text id='part.send-owner-excuse'>Send owner excuse</Text>
                                        </small>
                                </div>
                            </>}
                        </div>
                        <HelpText>
                            <Text id='part.owner-help'>If this part is owned by someone else, you can send them a reminder to pick it up or an excuse if you forgot to return it.</Text>
                        </HelpText>
                    </>}

                    <PartUsageList
                        stores={this.props.stores}
                        usages={part.usages}
                        classNames='mt-5'
                        hideEmpty={true}
                        view={this.viewPartUsage}
                        callback={this.loadAll}
                        editElement={this.editPartUsage}
                        help={partUsageListHelpText}
                    />

                    <PartLoanList
                        stores={this.props.stores}
                        loans={part.loans}
                        classNames='mt-5'
                        hideEmpty={true}
                        view={this.viewPartLoan}
                        callback={this.loadAll}
                        editQty={this.editPartLoan}
                        editElement={this.editPartLoan}
                        help={partLoanListHelpText}
                    />

                    <InstallationList
                        stores={this.props.stores}
                        installations={installations}
                        classNames='mt-5'
                        view={() => {}}
                        hideEmpty={true}
                        help={installationListHelpText}
                    />

                    <PartList
                        title={titleParentPartsText}
                        stores={this.props.stores}
                        parts={part.parents}
                        classNames={'mt-5'}
                        hideEmpty={true}
                        view={this.viewPart}
                        addItem={opts.showAddParts ? this.addPart : null}
                        inc={this.incPart}
                        dec={this.decPart}
                        remove={this.removePart}
                        editQty={this.editPartQty}
                        deleteElement={this.deletePartFromInstallation}
                        rentOutElement={this.loanPartToFriend}
                        moveElement={this.addPartToInstallation}
                        addUsageData={this.addUsageData}
                        editElement={this.editPart}
                    >
                        <Text id='part.parent-list-help'>Parent part this is related to and dependent on.</Text>
                    </PartList>

                    <PartList
                        title={titleChildrenPartsText}
                        stores={this.props.stores}
                        parts={part.children}
                        classNames={'mt-5'}
                        hideEmpty={true}
                        view={this.viewPart}
                        addItem={opts.showAddParts ? this.addPart : null}
                        inc={this.incPart}
                        dec={this.decPart}
                        remove={this.removePart}
                        editQty={this.editPartQty}
                        deleteElement={this.deletePartFromInstallation}
                        rentOutElement={this.loanPartToFriend}
                        moveElement={this.addPartToInstallation}
                        addUsageData={this.addUsageData}
                        editElement={this.editPart}
                    >
                        <Text id='part.children-list-help'>Other parts that are connected to and rely on this part.</Text>
                    </PartList>
                </div>
            </div>
        </>);
    }
}

export default Part;
